<div
    *ngIf="!socialType else socialButton"
    [ngClass]="{ 'obf-loader-show': loader, 'full-width': isFullWidth, 'auto-width': type === 'tertiary' || isAutoWidth}"
    class="button-wrapper {{ size + '-size' }} {{ documentDirection }}">
    <button
        type="{{isSubmitButton ? 'submit': 'button'}}"
        class="{{ type }} {{ disable || inactive ? '' : state }}"
        [ngClass]="{'disabled': disable, 'inactive': inactive, 'with-text': additionalText}"
    >
        <span  [ngClass]="{'has-prefix': isIconAsPrefix, 'has-suffix': !isIconAsPrefix && iconName}" class="button-label">
            {{ label }}
            <span *ngIf="iconName" class="button-icon obf-icon {{ iconName }}"></span>
        </span>
    </button>
    <span *ngIf="additionalText" class="button-additional-text"> {{ additionalText }}</span>
</div>

<ng-template #socialButton>
    <div [ngSwitch]="type">
        <div class="obf-btn-wrap obf-google-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
        *ngSwitchCase="'Google'" [ngClass]="{ 'obf-loade-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="obf-btn obf-google"
                [ngClass]="{ 'obf-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="obf-icon-wrap">
                    <img class="obf-google-icon" src="./google-logo.png" />
                </div>
                <span>{{ socialType === 'login' ? socialLabels.login.googleLabel : socialLabels.register.googleLabel }} <span class="button-type">{{ name }}</span></span>
            </button>
            <div class="obf-load-eclipse">
                <div class="obf-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="obf-btn-wrap obf-facebook-wrap" *ngSwitchCase="'Facebook'"
            [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }" [ngClass]="{ 'obf-loader-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="obf-btn obf-facebook"
                [ngClass]="{ 'obf-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="obf-icon-wrap">
                    <img class="obf-icon-facebook-new" src="./facebook-logo.png" />
                </div>
                <span>{{ socialType === 'login' ? socialLabels.login.facebookLabel : socialLabels.register.facebookLabel }} <span class="button-type">{{ name }}</span></span>
            </button>
            <div class="obf-load-eclipse">
                <div class="obf-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="obf-btn-wrap obf-apple-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Apple'" [ngClass]="{ 'obf-loade-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="obf-btn obf-apple"
                [ngClass]="{ 'obf-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="obf-icon-wrap">
                    <img class="obf-apple-icon" src="./apple-logo.png" />
                </div>
                <span>{{ socialType === 'login' ? socialLabels.login.appleLabel : socialLabels.register.appleLabel }} <span class="button-type">{{ name }}</span></span>
            </button>
            <div class="obf-load-eclipse">
                <div class="obf-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="obf-btn-wrap obf-azure-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
            *ngSwitchCase="'Microsoft'" [ngClass]="{ 'obf-loade-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="obf-btn obf-azure"
                [ngClass]="{ 'obf-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="obf-icon-wrap">
                    <img class="obf-azure-icon" src="./microsoft-social-logo.png" />
                </div>
                <span>{{ socialType === 'login' ? socialLabels.login.azureLabel : socialLabels.register.azureLabel }} <span class="button-type">{{ name }}</span></span>
            </button>
            <div class="obf-load-eclipse">
                <div class="obf-load-lds-eclipse"></div>
            </div>
        </div>

        <div class="obf-btn-wrap obf-oauth-wrap" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }"
        *ngSwitchCase="'OAuth2'" [ngClass]="{ 'obf-loade-show': loader }">
            <button type="{{isSubmitButton ? 'submit': 'button'}}" class="obf-btn obf-oauth2"
                [ngClass]="{ 'obf-disable': disable }" [ngStyle]="{ width: isFullWidth ? '100%' : 'auto' }">
                <div class="obf-icon-wrap">
                    <img class="obf-oauth-icon" src="./lock.png" />
                </div>
                <span>{{ socialType === 'login' ? socialLabels.login.oauth2Label : socialLabels.register.oauth2Label }} <span class="button-type">{{ name }}</span></span>
            </button>
            <div class="obf-load-eclipse">
                <div class="obf-load-lds-eclipse"></div>
            </div>
        </div>
    </div>
</ng-template>
