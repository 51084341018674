import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorReportingService } from '@services/error-reporting.service';
import { NavigationService } from '@services/navigation.service';
import { deprecatedKeywords } from 'src/assets/languages/deprecated-keywords';
import { localLanguages } from 'src/assets/languages/languages';
import { transformLanguageKey } from '../helpers/global-functions';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@services/local-storage.service';
import { ChoicesPositions } from '@constants/choice';

@Injectable()
export class LocalizationProvider {
    private currentLanguage: string = 'en';
    private defaultLanguage: string = 'en';
    private profileLanguages: any = null;
    public activeLanguages: any = null;
    public activeLanguage: BehaviorSubject<string> = new BehaviorSubject('');
    public staticConfigurationTexts: any = null;

    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _errorReportingService: ErrorReportingService,
        private _localStorageService: LocalStorageService
    ) {}

    public setStaticConfigurationTexts(texts) {
        this.staticConfigurationTexts = texts;
    }

    /**
     * Set app language
     * @param language language
     */
    public setAppLanguage(language: string, isInit?: boolean): void {

        this.setDefaultLanguageInLS(language);

        if (language) {
            language = transformLanguageKey(language);

            this._navigationService.setCurrentLanguage(language);

            this.currentLanguage = language;
            
            this.activeLanguage.next(language);
            const currentPosition: any =
                this._navigationService.getCurrentStep();

            if (!isInit) {

                // Trick angular so it can raload the same route.
                // Note: Use this only on this case!
                this._router.routeReuseStrategy.shouldReuseRoute = () => false;

                const nextPosition: string = currentPosition.activeChild
                        ? currentPosition.activeChild.position
                        : currentPosition.position,
                    pathParams: Array<any> = !currentPosition.children
                        ? currentPosition.pathParams
                        : [];

                this._navigationService.navigateMe(nextPosition, {
                    pathParams,
                });
            }
        }
    }

    public setActiveLanguages(languages) {
        this.activeLanguages = languages;
    }

    public getActiveLanguages() {
        return this.activeLanguages;
    }

    /**
     * Profile languages setter
     * @param languages languages from profile config
     */
    public setProfileLanguages(languages: any): void {
        const languageKeywords: Array<String> = Object.keys(languages);

        let languagesObject: any = {};

        languageKeywords.forEach((keyword: string) => {
            const transformedKeyword: string = transformLanguageKey(keyword);

            languagesObject[transformedKeyword] = languages[keyword];
        });

        this.profileLanguages = languagesObject;
    }

    /**
     * Check if keywords which are set in profile doest not exist in local file
     */
    public validateLanguages(): any {
        const missingKeywordsFromLocalFile: Array<string> = [];

        if (this.profileLanguages) {
            const profileLanguagesKeywords: Array<string> = Object.keys(
                this.profileLanguages
            );

            // iterate through all languages in profile - en_GB, etc..
            for (
                let profileLanguageKeywordIndex = 0;
                profileLanguageKeywordIndex < profileLanguagesKeywords.length;
                profileLanguageKeywordIndex++
            ) {
                const profileLanguageKeyword: string =
                        profileLanguagesKeywords[profileLanguageKeywordIndex],
                    currentProfileLanguage: any =
                        this.profileLanguages[profileLanguageKeyword],
                    currentProfileLanguageKeywords = Object.keys(
                        currentProfileLanguage
                    );

                // local file has the language that we are currently iterating in profile languages
                if (localLanguages.hasOwnProperty(profileLanguageKeyword)) {
                    // iterate through all keywords in currently iterated language from profile
                    for (
                        let currentLanguageKeywordIndex = 0;
                        currentLanguageKeywordIndex <
                        currentProfileLanguageKeywords.length;
                        currentLanguageKeywordIndex++
                    ) {
                        const currentLanguageKeyword: string =
                            currentProfileLanguageKeywords[
                                currentLanguageKeywordIndex
                            ];

                        // keyword from profile language doesnt exist in local language
                        if (
                            !localLanguages[
                                profileLanguageKeyword
                            ].hasOwnProperty(currentLanguageKeyword)
                        ) {
                            let formattedLanguageKeyword: string =
                                currentLanguageKeyword;

                            if (
                                this.isLanguageKeywordDeprecated(
                                    currentLanguageKeyword
                                )
                            ) {
                                formattedLanguageKeyword = `${formattedLanguageKeyword} [deprecated]`;
                            }

                            missingKeywordsFromLocalFile.push(
                                formattedLanguageKeyword
                            );
                        }
                    }
                }
            }

            if (missingKeywordsFromLocalFile.length) {
                // Send report
                const errorModel = {
                    reason: 'Unknown language keywords in profile config',
                    missingKeywords: missingKeywordsFromLocalFile,
                };

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    errorModel.reason
                );
            }
        }
    }

    /**
     * Get text from current language or return the word from english language as a backup
     * @param keyword text keyword
     */
    public getLocalizedText(keyword: string): string {
        
        // Check in general texts in profile config and return
        if (this.staticConfigurationTexts && this.staticConfigurationTexts.hasOwnProperty(keyword)) {
            return this.staticConfigurationTexts[keyword];
        }

        let localizedText: string = null,
            textFoundFromLocalFileInCurrentLang: boolean =
                localLanguages[this.currentLanguage] &&
                localLanguages[this.currentLanguage].hasOwnProperty(keyword) || localLanguages[this.defaultLanguage] &&
                localLanguages[this.defaultLanguage].hasOwnProperty(keyword);

        // first level check - text is not found in our local file
        if (!textFoundFromLocalFileInCurrentLang) {
            // text keyword is deprecated
            if (this.isLanguageKeywordDeprecated(keyword)) {
                const oldKeyword: string = keyword,
                    newKeyword: string = this.getNewLanguageKeyword(oldKeyword),
                    errorModel = {
                        reason: 'Deprecated language keyword used',
                        keyword: `keyword used: ${keyword}, new keyword: ${newKeyword}`,
                    };

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    errorModel.reason
                );

                // overwrite passed keyword parameter with the new one
                keyword = newKeyword;
            } else {
                // keyword spelling is messed up or something alike
                const errorModel = {
                    reason: 'Unknown language keyword used',
                    keyword: `keyword used: ${keyword}`,
                };

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    errorModel.reason
                );

                return localizedText;
            }
        }

        // As first priority search the text from languages in profile config
        if (
            this.profileLanguages &&
            this.profileLanguages[this.currentLanguage] &&
            this.profileLanguages[this.currentLanguage].hasOwnProperty(
                keyword
            ) &&
            this.profileLanguages[this.currentLanguage][keyword]
        ) {
            // Text found in current language [profile config]
            localizedText =
                this.profileLanguages[this.currentLanguage][keyword];
        } else if (
            localLanguages[this.currentLanguage] &&
            localLanguages[this.currentLanguage].hasOwnProperty(keyword)
        ) {
            // Text found in current language [local file]
            localizedText = localLanguages[this.currentLanguage][keyword];
        } else {
            if (
                localLanguages[this.defaultLanguage] &&
                localLanguages[this.defaultLanguage].hasOwnProperty(keyword)
            ) {
                // Text found in default language [local file]
                localizedText = localLanguages[this.defaultLanguage][keyword];
                
                // DEBUG !!!!!!!! switch to default after debug with bg
                // localizedText = localLanguages['bg'][keyword];
            } else {
                // this is handled from above
            }
        }

        return localizedText;
    }

    /**
     * Current language getter
     */
    public getCurrentLanguage(): string {
        return this.currentLanguage;
    }

    /**
     * Profile languages getter
     * @param languages languages from profile config
     */
    public getProfileLanguages(): any {
        return this.profileLanguages;
    }

    /**
     * Decides wheter to show interface for changing app language
     */
    public hasAvailableLanguages(): boolean {
        if (
            this.activeLanguages?.length > 1
        ) {
            return true;
        }

        return false;
    }

    /**
     * Checks specific keyword for being deprecated in our config file for deprecated keywords
     * @param keyword text keyword
     */
    private isLanguageKeywordDeprecated(keyword: string): boolean {
        let isDeprecated = false;

        if (deprecatedKeywords && deprecatedKeywords.length) {
            const keywordMatch: boolean = deprecatedKeywords.find(
                (keywordObject: { oldKeyword: string; newKeyword: string }) =>
                    keyword === keywordObject.oldKeyword
            )
                ? true
                : false;

            isDeprecated = keywordMatch;
        }

        return isDeprecated;
    }

    /**
     * Retrieves new keyword for the deprecated one
     * @param oldKeyword deprecated keyword
     */
    private getNewLanguageKeyword(oldKeyword: string): string {
        if (!this.isLanguageKeywordDeprecated(oldKeyword)) {
            return;
        }

        return deprecatedKeywords.find(
            (keywordObject: { oldKeyword: string; newKeyword: string }) =>
                oldKeyword === keywordObject.oldKeyword
        ).newKeyword;
    }

    public getTextsFromModule(module) {
        if (!module) return;

        return module.texts ? module.texts : null
    }

    public setDefaultLanguageInLS(language) {
        this._localStorageService.setItem('app_default_language', language);
    }
}
