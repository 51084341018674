import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class AdditionalService {
    constructor(private _localizationProvider: LocalizationProvider) {}

    public documentDirection: BehaviorSubject<string> = new BehaviorSubject('');

    public setDocumentDirection(direction) {
        document.dir = direction;
        this.documentDirection.next(direction);
    }

    public getDirection() {
        const currentLanguage: string = this._localizationProvider.getCurrentLanguage();

        if (currentLanguage === 'ar') {
            return 'rtl';
        }

        return 'ltr';
    }
}
