import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { LocalizationProvider } from '@providers/localization.provider';
import { AdditionalService } from '@services/additional.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
    /**
     * Loader reference
     */
    @ViewChild(LoaderComponent)
    public loaderComponentRef: LoaderComponent;
    public changeUrl = true;

    /**
     * Imports
     * @param _loaderService
     * @param router
     */
    constructor(
        private _loaderService: LoaderService,
        private _eventTrackingService: EventTrackingService,
        private _localizationProvider: LocalizationProvider,
        private _additionalService: AdditionalService
    ) {
        
        let direction = 'ltr';
        let currentLanguageData = null;

        const currentLanguage: string = this._localizationProvider.getCurrentLanguage();
        const currentLanguages = this._localizationProvider.getProfileLanguages();

        if (currentLanguages?.length) {
            currentLanguageData = currentLanguages.find(
                (language) => {
                    return language.code === currentLanguage
                }
            )
        }

        direction = currentLanguageData?.text_direction ? currentLanguageData.text_direction : 'ltr';

        // Backup - handle if language is arabic
        if (currentLanguage === 'ar') {
            direction = 'rtl';
        }

        this._additionalService.setDocumentDirection(direction);
    }

    @HostListener('document:click', ['$event', '$event.target'])
    documentClick(event: MouseEvent, targetElement: HTMLElement) {

        // Send event if clicked element has textContent
        if (targetElement?.textContent) {
            const dataLayerObject = {
                event: 'obf_clicks',
                version: 'GA4',
                click_text: targetElement?.textContent
            };
            this._eventTrackingService.push(dataLayerObject);
        }
    }


    public ngAfterViewInit(): void {
        // console.log('LoaderComponent reference: ', this.loaderComponentRef);
        this._loaderService.setLoaderRef(this.loaderComponentRef);
    }
}
