import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { AdditionalService } from '@services/additional.service';
import { Subscription } from 'rxjs';
import { MainDataProvider } from 'src/app/providers/xrm/main-data.provider';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss', './../../../../../configure-booking-transaction/components/pages/before-confirmation/before-confirmation.component.scss'],
})
export class ButtonComponent implements OnDestroy {
    @Input()
    public label = '';

    @Input()
    public name = '';

    @Input()
    public socialType: string = '';

    public socialLabels = {
        login: {
            appleLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            googleLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            facebookLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            azureLabel :  this._localizationProvider.getLocalizedText('socialAuthLabel3'),
            oauth2Label :  this._localizationProvider.getLocalizedText('socialAuthLabel3')
        },
        register: {
            appleLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            googleLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            facebookLabel: this._localizationProvider.getLocalizedText('socialAuthLabel2'),
            azureLabel :  this._localizationProvider.getLocalizedText('socialAuthLabel4'),
            oauth2Label :  this._localizationProvider.getLocalizedText('socialAuthLabel4')
        },
    };

    @Input()
    public isSubmitButton = false;

    @Input()
    public disable = false;

    @Input()
    public loader = false;

    @Input()
    public type: string = 'primary'; /** primary | secondary | tertiary | Google | Facebook | Apple */

    @Input()
    public state: string = 'default'; /** default | hover| pressed | disabled | inactive */

    @Input()
    public size: string = 'normal'; /** small | normal | large */
    
    @Input()
    public inactive: boolean = false;

    @Input()
    public iconName: string = ''; /** icon name from our font */

    @Input()
    public isIconAsPrefix: boolean = false;

    @Input()
    public additionalText: string = ''; /** small text placed under the button */

    @Input()
    public isFullWidth: boolean = false;

    @Input()
    public isAutoWidth: boolean = false;

    private subscriptions: Subscription[] = [];

    public documentDirection = '';

    constructor(
        public _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
        private _additionalService: AdditionalService
    ) {

        this.subscriptions.push(
            this._additionalService.documentDirection.subscribe(
                (direction) => {
                    this.documentDirection = direction;
                }
            )
        )
    }

    public ngOnDestroy() {
        this.subscriptions.map((el) => el.unsubscribe());
    }
}
