import { environment } from './../../environments/environment';
import { ChatService } from 'src/app/services/chat.service';
import { Injectable } from '@angular/core';
import { ChoiceItemsTypes } from '@constants/choice-items';
import { Choice } from '@models/choice';
import { ChoiceItem } from '@models/choice-item';
import { Service } from '@models/service';
import { BookingTransactionsService } from '@services/booking-transactions.service';
import { ServicesService } from '@services/services.service';
import { UserDataService } from '@services/user-data.service';
import { NGXLogger } from 'ngx-logger';
import { ObfOptions } from '../../../lib/obf-client/src/interfaces/obf-options.interface';
import { ChoicesPositions } from '../constants/choice';
import { getUrlVars, simplifyChoices } from '../helpers/global-functions';
import {
    checkIsObject,
    stripNonAlphanumericChars,
} from '../helpers/helper-functions';
import { MainDataProvider } from '../providers/xrm/main-data.provider';
import { OrderData } from '../providers/xrm/order-data.provider';
import { AuthenticationService } from './authentication/authentication.service';
import { DataLayerPush } from './data-layer.service';
import { TrackingInteractionsService } from '../services/tracking/data-interaction.service';
import { ErrorReportingService } from './errors/error-reporting.service';
import { EventTrackingService } from './tracking/event-tracking.service';

import { LoaderService } from './loader.service';
import { ModalsService } from './modals.service';
import { NavigationService } from './navigation.service';
import { PopupModalsService } from './popup-modals.service';
import { PostMessageService } from './post-message.service';
import { QueryParamsService } from './query-params.service';
import { LocalizationProvider } from '@providers/localization.provider';
import { ServerResponse } from '@models/server-response';

@Injectable({
    providedIn: 'root',
})
export class AppManagerService {
    /**
     * Is the first open of the app for the current page.
     */
    public appFirstOpen = true;

    /**
     * Is app visible or not for user.
     */
    public appState: 'start-session' | 'visible' | 'not-visible' =
        'not-visible';

    constructor(
        private _userDataService: UserDataService,
        private _bookingTransactionsService: BookingTransactionsService,
        private _eventTrackingService: EventTrackingService,
        private _trackingInteractionsService: TrackingInteractionsService,
        private _dataLayerPush: DataLayerPush,
        private _queryParamsService: QueryParamsService,
        private _orderData: OrderData,
        private loaderService: LoaderService,
        private _mainDataProvider: MainDataProvider,
        private _servicesService: ServicesService,
        private postMessageService: PostMessageService,
        private _navigationService: NavigationService,
        private popupModalsService: PopupModalsService,
        private logger: NGXLogger,
        private _modalsService: ModalsService,
        private _errorReportingService: ErrorReportingService,
        private authenticationService: AuthenticationService,
        private chatService: ChatService,
        private _localizationProvider: LocalizationProvider,
    ) {
        this.chatService; // force load of the chat.
    }

    /**
     * Subscribe for the site event for opening obf.
     */
    public subscribeOpeningClosingEvent(): void {
        this.postMessageService.openFormSubject.subscribe((e) => {
            this.beginSession(e);
        });

        this.postMessageService.closeFormSubject.subscribe((e) => {
            this.setAppStateNotVisible();
        });

        window.addEventListener('unload', (event) => {
            // Reset flag that init step was skipped when you leave OBF
            this._navigationService.setSkipFlag(false);
            
            const formState = this._orderData.getFormState();
            const bookingType = this._orderData?.bookingType;

            if (formState) {
                const dataLayerObject = {
                    event: 'obf_close',
                    version: 'GA4',
                    type: 'exit_tab',
                    method: bookingType
                };
                this._eventTrackingService.push(dataLayerObject);
            }
        });
    }

    /**
     * Used to open the App when is inside iframe.
     * @param obfOptions
     */
    public async beginSession(obfOptions: ObfOptions): Promise<any> {
        this.appState = 'start-session';

        let loader = this.loaderService.showLoader();

        // Set default page to start user session.
        let startSessionFromState: {
            position: ChoicesPositions;
            choice_id?: null | number;
        } = {
            position: ChoicesPositions.Init,
        };

        const navigationCallback: Function | null = null; // maybe i will not need for this.

        // ! remove this. need to go where is use and change the code for now stays hare.
        this._dataLayerPush.setVisible(true);
        this._trackingInteractionsService.setVisible(true);
        this._orderData.setFormState(true);
        // ! ----

        // Ensure when have opening but the options are broken to open the app with initial obfOptions
        if (!checkIsObject(obfOptions)) {
            obfOptions = this._mainDataProvider.getInitializeObfOptions();
        }

        // TODO this logic need to be move in client. expect the part where check if we have title.
        if (
            !obfOptions.default_service_id &&
            obfOptions.default_service_title
        ) {
            const service: any = await this.fetchServiceByTitle(
                obfOptions.default_service_title
            );

            if (service && service.id) {
                obfOptions.default_service_id = service.id;
            }
        } else if (obfOptions.fs_payload) {
            Object.keys(obfOptions.fs_payload).map((paramKey) => {
                switch (paramKey) {
                    case 'postcode':
                        obfOptions.postcode = obfOptions.fs_payload[paramKey];
                        break;
                    case 'service_id':
                        obfOptions.default_service_id =
                            obfOptions.fs_payload[paramKey];
                        break;
                    case 'category_id':
                        obfOptions.default_category_id =
                            obfOptions.fs_payload[paramKey];
                        break;
                    case 'voucher':
                        obfOptions.voucher = obfOptions.fs_payload[paramKey];
                        break;
                    case 'booking_transaction_id':
                        obfOptions.booking_transaction_id =
                            obfOptions.fs_payload[paramKey];
                        break;
                    case 'booking_id':
                        obfOptions.booking_id = obfOptions.fs_payload[paramKey];
                        break;
                    case 'hide_phone':
                        obfOptions.hide_phone = obfOptions.fs_payload[paramKey];
                        break;
                    case 'exit_position':
                        obfOptions.exit_position = obfOptions.fs_payload[paramKey];
                        break;
                    default:
                        console.warn(
                            'Unknown obfOption param in payload' + paramKey
                        );
                        break;
                }
            });
        }

        // * On first open we look for query params and modify the obfOptions.
        if (this.appFirstOpen) {
            this.appFirstOpen = false; // * Set the flag to false after first open.
            const queryParams = this._queryParamsService.getQueryParams();

            if (queryParams.postcode && queryParams.postcode !== 'undefined') {
                obfOptions.postcode = queryParams.postcode;
            }

            if (queryParams.cross_id) {
                obfOptions.cross_id = parseInt(queryParams.cross_id, 10);
            }

            if (queryParams.cross_token) {
                obfOptions.cross_token = queryParams.cross_token;
            }

            if (queryParams.voucher) {
                obfOptions.voucher = queryParams.voucher;
            }

            if (
                queryParams.service_id &&
                queryParams.service_id !== 'undefined'
            ) {
                obfOptions.default_service_id = queryParams.service_id;
            }

            if (queryParams.category_id) {
                obfOptions.default_category_id = queryParams.category_id;
            }

            if (queryParams.promo_deal) {
                obfOptions.default_service_id = queryParams.promo_deal;
            }

            if (queryParams.free_quote) {
                obfOptions.free_quote = queryParams.free_quote;
            }

            if (queryParams.covering_postcode) {
                obfOptions.covering_postcode = queryParams.covering_postcode;
            }

            if (queryParams.campaign_phone) {
                obfOptions.phone = queryParams.campaign_phone;
            }

            if (queryParams.lang) {
                obfOptions.lang = queryParams.lang;
            }

            if (queryParams.campaign_id) {
                obfOptions.campaign_id = queryParams.campaign_id;
            }

            if (queryParams.campaign_abbr) {
                obfOptions.campaign_abbr = queryParams.campaign_abbr;
            }

            if (queryParams.exit_position) {
                obfOptions.exit_position = queryParams.exit_position;
            }

            if (queryParams.hide_phone) {
                obfOptions.hide_phone = Boolean(queryParams.hide_phone);
            }

            if (queryParams.booking_transaction_id) {
                obfOptions.booking_transaction_id =
                    queryParams.booking_transaction_id;
            }

            if (queryParams.exit_position) {
                obfOptions.exit_position =
                    queryParams.exit_position;
            }
        }

        // * Get secure we have categories and services for the session.
        // await this.fetchCategories(true);

        // * Seth the builded Options for the session.
        this.logger.debug('Builded options for current session:', obfOptions);
        this._mainDataProvider.setResourceObfOptions(obfOptions);

        // * Reset the active booking to secure clean start for the session.
        this._orderData.resetActiveBooking();

        let defaultServiceId: any = null;

        // * Base on options we determine type of the session.
        let appSessionType:
            | 'restore'
            | 'edit'
            | 'rebook'
            | 'normal'
            | 'reschedule'
            | 'procrossbooking'
            | 'skipcreate' = 'normal';

        if (
            obfOptions.booking_transaction_id &&
            obfOptions.booking_transaction_id.length >= 32
        ) {
            appSessionType = 'restore';
        } else if (obfOptions.booking_id && obfOptions.action) {
            appSessionType = obfOptions.action; // * edit , rebook, reschedule
        } else if (
            obfOptions.cross_id &&
            obfOptions.cross_token &&
            obfOptions.default_service_id &&
            obfOptions.postcode
        ) {
            defaultServiceId = obfOptions.default_service_id;

            appSessionType = 'procrossbooking';
        } else if (obfOptions.default_service_id && obfOptions.postcode) {
            defaultServiceId = obfOptions.default_service_id;

            appSessionType = 'skipcreate';
        } else if (obfOptions.default_service_id && !obfOptions.postcode) {

            let serviceResponse: ServerResponse<Service>;
            serviceResponse = (
                await this._servicesService.fetchService(obfOptions.default_service_id, { validate: false })
                    .catch((error: ServerResponse<Service>) => {
                        // error is handle in on-welcome component
                    })
            ) as ServerResponse<Service>;
            
            if (!serviceResponse) {
                appSessionType = 'normal';
            } else {
                const serviceData: Service = serviceResponse?.data;
                const choices: Choice[] = serviceData.choices as Choice[];

                // Fill the postcode in address choice item.
                const initChoices = this._servicesService
                    .getChoicesByPositions(choices, ChoicesPositions.Init);

                if (!initChoices?.length && obfOptions.default_service_id) {
                    defaultServiceId = obfOptions.default_service_id;
                    this._servicesService.setServicesWithoutInitChoice(defaultServiceId);

                    appSessionType = 'skipcreate';
                } else {
                    appSessionType = 'normal';
                }
            }

        } else {
            appSessionType = 'normal';
        }
        
        this._servicesService.setSessionType(appSessionType);

        const authRestrictions = await this._userDataService.authRestrictions();

        // * Secure user is login for sessions types: edit, rebook, restore, reschedule.
        if (
            !this._userDataService.isUserLogged() &&
            authRestrictions === 'required_auth' &&
            ['edit', 'rebook', 'restore', 'reschedule'].includes(appSessionType)
        ) {
            obfOptions.default_service_id = null;

            const navigation = await this.forceNavigationToInitStep();

            if (navigation) {
                this.postMessageService.sendState('show', true); // tell the client to show Obf
                this.appState = 'visible';
                this.loaderService.hideLoader(loader);

                // Open the authentication modal and wait for user interaction.
                try {
                    await this.openLoginForTheUser();
                } catch (error) {
                    // User don't authenticate. We change the session to normal and leave user on current page.
                    appSessionType = 'normal';
                    this._eventTrackingService.push({
                        event: 'obf_open',
                        version: 'GA4',
                        // bookingType: this._orderData.bookingType,
                        // gtmCid: obfOptions.hasOwnProperty('gtm_cid') ? obfOptions.gtm_cid : null,
                    });
                    

                    const dataObject = {};
                    Object.assign(dataObject, {
                        source: obfOptions.hasOwnProperty('phone')
                            ? obfOptions.phone
                            : null,
                        main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                    });
                    this._eventTrackingService.sosInteractionsPush('open_booking_form', dataObject); 
                    return;
                }

                // after user login show again loader.
                loader = this.loaderService.showLoader();
            } else {
                // !TODO SEND EMAIL ERROR CRITICAL ERROR.
                // this should not happens.
            }
        }

        // ! Make sure to create anonymous registration in these cases
        if (['edit', 'rebook', 'restore', 'raeschedule'].includes(appSessionType)) await this._bookingTransactionsService.checkForDisabledAccounts();

        // ! PRO CROSS SELL TRANSACTION.
        if (appSessionType === 'procrossbooking') {
            try {
                await this.authenticationService.logout();

                await this._bookingTransactionsService.proCrossSellTransaction(
                    obfOptions.cross_id,
                    obfOptions.cross_token,
                    defaultServiceId,
                    obfOptions.postcode
                );
                const state = this._orderData.getActiveTransactionProgress();

                if (state) {
                    startSessionFromState = state;
                }
            } catch (err) {
                const errorModel: any = {
                        reason: 'Fail to start procrossbooking session',
                        errorResponse: err,
                    },
                    reportSubject: string = errorModel.reason;

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    reportSubject
                );

                this._modalsService.open('defaultError', {
                    callback: () => {
                        this.popupModalsService.openExitPopup();
                    },
                });
            }
        }

        // ! RESTORE TRANSACTION.
        if (appSessionType === 'restore') {
            try {
                const urlParams = getUrlVars();
                const response =
                    await this._bookingTransactionsService.restoreTransaction(
                        obfOptions.booking_transaction_id
                    );

                if (
                    !(urlParams && urlParams.hasOwnProperty('payment_intent')) &&
                    response &&
                    response.data &&
                    response.data.reference_number
                ) {
                    const modalData = {
                        message: this._localizationProvider.getLocalizedText('modalErrMsg5'),
                        buttons: [
                            {
                                label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                                closeOnClick: true,
                                type: 'primary',
                                state: 'default',
                                size: 'normal',
                            },
                        ],
                    };

                    this._modalsService.open('custom', modalData);

                    // We fail to restore the transaction.So we going to leave user on init step.
                    appSessionType = 'normal';
                } else {
                    const state =
                        this._orderData.getActiveTransactionProgress();

                    if (state) {
                        startSessionFromState = state;
                    }

                    if (response.validationCallback) {
                        response.validationCallback();
                    }
                }
            } catch (e) {
                // We fail to restore the transaction.So we going to leave user on init step.
                appSessionType = 'normal';
            }
        }

        // ! REBOOK COMPLETED BOOKING.
        if (appSessionType === 'rebook') {
            const reportBookingId = obfOptions.booking_id;

            try {
                await this._bookingTransactionsService.rebookBooking(
                    obfOptions.booking_id,
                    {
                        validate: false,
                    }
                );

                const state = this._orderData.getActiveTransactionProgress();

                if (state) {
                    startSessionFromState = state;
                }
            } catch (err) {
                const errorModel: any = {
                    reason: `Fail to start ${appSessionType} session`,
                    booking_id: reportBookingId,
                    errorResponse: err,
                };
                const reportSubject: string = errorModel.reason;

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    reportSubject
                );

                this._modalsService.open('defaultError', {
                    callback: () => {
                        if (appSessionType !== 'rebook') {
                            this.popupModalsService.openExitPopup();
                        }
                    },
                });
            }

            // ! Reset the options for the action and booking_id.
            const obfOptionsChange =
                this._mainDataProvider.getResourceObfOptions();
            obfOptionsChange.action = undefined;
            obfOptionsChange.booking_id = undefined;
        }

        // ! EDIT COMPLETED BOOKING.
        if (appSessionType === 'reschedule' || appSessionType === 'edit') {
            const reportBookingId = obfOptions.booking_id;
            try {
                await this._bookingTransactionsService.editBooking(
                    obfOptions.booking_id,
                    {
                        validate: false,
                    }
                );
                const state = this._orderData.getActiveTransactionProgress();

                if (state) {
                    startSessionFromState = state;
                }
            } catch (err) {
                const errorModel: any = {
                    reason: `Fail to start ${appSessionType} session`,
                    booking_id: reportBookingId,
                    errorResponse: err,
                };
                const reportSubject: string = errorModel.reason;

                this._errorReportingService.report(
                    {
                        emailErrors: [errorModel],
                    },
                    false,
                    reportSubject
                );

                let errorMessage =
                    this._localizationProvider.getLocalizedText('modalErrMsg2');
                if (err && err.error && err.error[0] && err.error[0].message) {
                    errorMessage = err.error[0].message;
                }

                const modalData = {
                    message: errorMessage,
                    buttons: [
                        {
                            label: this._localizationProvider.getLocalizedText('modalDefaultConfirmButtonTitle'),
                            closeOnClick: true,
                            type: 'primary',
                            state: 'default',
                            size: 'normal',
                            action: () => {},
                        },
                    ],
                    callback: () => {
                        appSessionType = 'normal';
                    },
                };

                this._modalsService.open('custom', modalData);
            }

            // ! Reset the options for the action and booking_id.
            const obfOptionsChange =
                this._mainDataProvider.getResourceObfOptions();
            obfOptionsChange.action = undefined;
            obfOptionsChange.booking_id = undefined;
        }

        // ! SKIP TRANSACTION CREATE
        if (appSessionType === 'skipcreate') {
            try {
                const state = await this.skipTransactionCreation(
                    defaultServiceId,
                    obfOptions.postcode
                    );

                this._orderData.bookingType = 'normal';

                // navigate user to init position secure login popup requested from navigation.
                const navigation = await this.forceNavigationToInitStep();

                if (navigation) {
                    startSessionFromState = state;

                    this.appState = 'visible';
                    this.postMessageService.sendState('show', true);
                    this._eventTrackingService.push({
                        event: 'obf_open',
                        version: 'GA4',
                        // bookingType: this._orderData.bookingType,
                        // gtmCid: obfOptions.hasOwnProperty('gtm_cid') ? obfOptions.gtm_cid : null,
                    });

                    const dataObject = {};
                    Object.assign(dataObject, {
                        source: obfOptions.hasOwnProperty('phone')
                            ? obfOptions.phone
                            : null,
                        main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                    });
                    this._eventTrackingService.sosInteractionsPush('open_booking_form', dataObject); 
                } else {
                    // should not happened
                }
            } catch (err) {
                // Set flag to true on error
                this._navigationService.setSkipFlag(true);
                if (err && err.position) {
                    startSessionFromState = err;
                }
            }
        }

        // Send any reports in stack
        this._errorReportingService.sendStackedReports();

        // * Hide loader.
        this.loaderService.hideLoader(loader);

        this._navigationService.navigateMe(startSessionFromState.position, {
            forceNavigate: true,
            pathParams: startSessionFromState.choice_id
                ? [startSessionFromState.choice_id]
                : [],
            successCB: (data) => {
                if (this.appState === 'visible') {
                    // the form is all ready visible
                } else {
                    this.appState = 'visible';
                    this.postMessageService.sendState('show', true);
                    this._eventTrackingService.push({
                        event: 'obf_open',
                        version: 'GA4',
                        // bookingType: this._orderData.bookingType,
                        // gtmCid: obfOptions.hasOwnProperty('gtm_cid') ? obfOptions.gtm_cid : null,
                    });

                    const dataObject = {};
                    Object.assign(dataObject, {
                        source: obfOptions.hasOwnProperty('phone')
                            ? obfOptions.phone
                            : null,
                        main_url: obfOptions.hasOwnProperty('main_url')
                            ? obfOptions.main_url
                            : null,
                    });
                    this._eventTrackingService.sosInteractionsPush('open_booking_form', dataObject); 
                }
            },
            errorCB: (data) => {
                // !TODO SEND ERROR WITH EMAIL.
                // This should not happened.Normal we have all the date for the transaction before trigged this navigation.

                this.postMessageService.sendState('show', true);

                this._eventTrackingService.push({
                    event: 'obf_open',
                    version: 'GA4',
                    // bookingType: this._orderData.bookingType,
                    // gtmCid: obfOptions.hasOwnProperty('gtm_cid') ? obfOptions.gtm_cid : null,
                });

                const dataObject = {};
                Object.assign(dataObject, {
                    source: obfOptions.hasOwnProperty('phone')
                        ? obfOptions.phone
                        : null,
                    main_url: obfOptions.hasOwnProperty('main_url')
                        ? obfOptions.main_url
                        : null,
                });
                this._eventTrackingService.sosInteractionsPush('open_booking_form', dataObject); 
            },
        });
    }

    /**
     * Method for force navigation to init step
     */
    private forceNavigationToInitStep(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._navigationService.navigateMe(ChoicesPositions.Init, {
                successCB: () => {
                    resolve(true);
                },
                errorCB: () => {
                    resolve(false);
                },
                forceNavigate: true,
            });
        });
    }

    /**
     * Method for parsing request error object and find coverage error
     * @param errors
     * @param serviceTitle
     * @param postcodeObject
     */
    public validateForCoverageError(
        errors: any[],
        serviceTitle: string = '',
        postcodeObject: any
    ): ChoicesPositions.NoCoverage | null {  
        let sendTo = null;

        errors.map((err) => {
            if (err.code === 6714) {
                // Postcode not covered error
                this._orderData.noCoverageObject = {
                    error: err,
                    service: serviceTitle || 'N/A',
                    postcodeObject,
                };
                this._orderData.coverageMessage = err.message;
                sendTo = ChoicesPositions.NoCoverage;
            }
        });

        // There's coverage error
        if (sendTo === ChoicesPositions.NoCoverage) {
            const obfOptions = this._mainDataProvider.getResourceObfOptions();
            const selectedServiceData = this._servicesService.getLastServiceInitData();

            obfOptions.postcode = null;
            obfOptions.default_service_id = null;

            const dataLayerObject = {
                event: 'obf_postcode_no_coverage',
                version: 'GA4',
                postcode: postcodeObject?.postcode,
                service_name: selectedServiceData?.title
            };
            this._eventTrackingService.push(dataLayerObject);
            
        }

        return sendTo;
    }

    /**
     * Method for redirecting to other service
     * @param redirectServiceId
     * @param triggerItem
     */
    public async redirectToOtherService(
        redirectServiceId: number,
        triggerItem: any,
        oldServiceData?: any
    ): Promise<any> {
        const currentTransaction = this._orderData.activeBooking.getValue()?.get();

        this._orderData.setServiceRedirectData(true, oldServiceData);

        const currentTransactionService = currentTransaction.service,
              currentTransactionVoucher = currentTransaction.voucher;

        let coveragePostcode = '';
        let coverageServiceTitle = '';

        let state: { position: ChoicesPositions; choice_id?: null | number } = {
            choice_id: null,
            position: ChoicesPositions.Init,
        };

        try {
            const redirectedServiceResponse =
                await this._servicesService.fetchService(redirectServiceId);

            const redirectService: Service = redirectedServiceResponse.data;
            coverageServiceTitle = redirectService.title;
            const choices: Choice[] = redirectService.choices as Choice[];

            // Fill the postcode in address choice item.
            this._servicesService
                .getChoicesByPositions(choices, ChoicesPositions.Init)
                .recursiveMap((el) => {
                    if (
                        el.customize &&
                        el.customize.hasOwnProperty(
                            'redirect_related_choice_item_ids'
                        ) &&
                        el.customize.redirect_related_choice_item_ids &&
                        el.customize.redirect_related_choice_item_ids !== ''
                    ) {
                        const relatedChoiceItems = JSON.parse(
                            el.customize.redirect_related_choice_item_ids
                        );

                        currentTransactionService.choices
                            .filter(
                                (activeElement) =>
                                    activeElement.positions.indexOf(
                                        ChoicesPositions.Init
                                    ) !== -1
                            )
                            .recursiveMap((activeElement: ChoiceItem) => {
                                if (
                                    relatedChoiceItems.indexOf(
                                        activeElement.id
                                    ) !== -1 &&
                                    activeElement.type ===
                                        ChoiceItemsTypes.Address
                                ) {
                                    el.value = activeElement.value;
                                    coveragePostcode = activeElement.value;
                                    // el.value = { postcode:'ll11ss' } // test bad coverage
                                }

                                return activeElement.choice_items;
                            });
                    }

                    return el.choice_items;
                });

            let sendData: { [key: string]: any } = {
                service: {
                    choices: simplifyChoices(choices),
                    id: redirectServiceId,
                },
            };

            // add current voucher again on serivce redirect
            if (currentTransactionVoucher) {
                sendData.voucher = currentTransactionVoucher;
            }

            if (this._orderData.bookingType === 'procrossbooking') {
                const obfOptions =
                    this._mainDataProvider.getResourceObfOptions();
                Object.assign(sendData, {
                    cross_id: obfOptions.cross_id,
                    cross_token: obfOptions.cross_token,
                });
            }

            const createTransactionResponse = await this.createTransaction(
                sendData
            );

            const btState = this._orderData.getActiveTransactionProgress();

            if (btState) {
                this._orderData.skipStep = true;
                this._orderData.redirect = true;

                state = btState;
            } else {
                this._orderData.resetActiveBooking();
            }

            this._navigationService.navigateMe(state.position, {
                pathParams: state.choice_id ? [state.choice_id] : [],
            });
        } catch (err) {
            let coverage;
            if (err && err.error) {
                coverage = this.validateForCoverageError(
                    err.error,
                    coverageServiceTitle,
                    coveragePostcode
                );
                if (coverage) {
                    state = {
                        position: ChoicesPositions.NoCoverage,
                    };
                }
            }

            this._navigationService.navigateMe(state.position, {
                pathParams: state.choice_id ? [state.choice_id] : [],
            });
        }
    }

    private getPostcodeFromActiveBooking() {
        const activeData = this._orderData.activeBooking.getValue() ? this._orderData.activeBooking.getValue().get() : null;
        const choices = activeData?.service?.choices?.length ? activeData.service.choices : null;

        if (!choices) return null;

        const initChoice = choices.find((choice) => {
            if (choice.hasOwnProperty('positions') && choice.positions) {
                return choice.positions?.indexOf(ChoicesPositions.Init) > -1;
            }
        });

        return initChoice &&
            initChoice.choice_items &&
            initChoice.choice_items.length
            ? initChoice.choice_items[0].value.postcode
            : null;
        
    }

    /**
     * Use to create transaction
     * ! For now before fully rework BookingTransactionService
     * @param createBookingData
     */
    private createTransaction(createBookingData): Promise<any> {
        return new Promise((resolve, reject) => {
            this._bookingTransactionsService.createTransaction(
                createBookingData,
                { expand: ['all.all.all.all'] },
                (validationResult: any, response: any) => {
                    if (validationResult.success) {
                        const resolveData: any = {
                            response,
                        };

                        if (
                            validationResult &&
                            validationResult.callbackMethod
                        ) {
                            Object.assign(resolveData, {
                                validationCallback:
                                    validationResult.callbackMethod,
                            });
                        }

                        resolve(resolveData);
                    } else {
                        reject(response);
                    }
                }
            );
        });
    }

    /**
     * Book another service
     */
    public bookAnotherService(): void {
        const loader = this.loaderService.showLoader();

        this._orderData.resetActiveBooking();

        const queryParams = {
            expand: ['all.all.all.all'],
            'paging[limit]': '9999',
        };

        // Get updated user data
        if (this._userDataService.getUserData()) {
            this._userDataService.fetchUserData(queryParams, () => {
                this._navigationService.navigateMe(ChoicesPositions.Init, {
                    forceNavigate: true,
                });
                this.loaderService.hideLoader(loader);
            });
        } else {
            this._navigationService.navigateMe(ChoicesPositions.Init, {
                forceNavigate: true,
            });
            this.loaderService.hideLoader(loader);
        }
    }

    /**
     * Find service by title.
     * @param title
     */
    private fetchServiceByTitle(title: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._servicesService
                .getServices(true)
                .then((res) => {
                    const service = res.find((el) => {
                        return (
                            stripNonAlphanumericChars(
                                el.title
                            ).toLowerCase() ===
                            stripNonAlphanumericChars(title).toLowerCase()
                        );
                    });

                    resolve(service);
                })
                .catch((err) => {
                    // TODO handle error
                });
        });
    }

    /**
     * App state setter
     */
    private setAppStateNotVisible(): void {
        this.appState = 'not-visible';
    }

    /**
     * use to fetch categories on every session start.
     * @param useCache
     */
    private fetchCategories(useCache: boolean): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this._servicesService
                .getCategories(useCache)
                .then((res) => {
                    resolve();
                })
                .catch((err) => {
                    // TODO handle error
                });
        });
    }

    /**
     * Method for opening login modal
     */
    private openLoginForTheUser(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.popupModalsService.openLoginRegister({
                callback: (data) => {
                    if (this._userDataService.isUserLogged()) {
                        resolve();
                    } else {
                        reject('User close login/register modal');
                    }
                },
                closeOnOverlayClick: false,
            });
        });
    }

    /**
     * This will skip the init steps for creating booking transaction.
     * If need will open authentication modal for user.
     * Have only resolve.
     * @param serviceId
     * @param postcode
     * @param additionData
     */
    public skipTransactionCreation(
        serviceId: string,
        postcode: string,
        additionData: { [key: string]: any } = {}
    ): Promise<{ position: ChoicesPositions; choice_id?: number | null }> {
        return new Promise(async (resolve, reject) => {
            let coverageServiceTittle = '';
            let serviceChoices = [];

            try {

                if (!this._servicesService.isServiceMissingInitChoice(serviceId)) {
                    const serviceServerResponse =
                    await this._servicesService.fetchService(serviceId);
                    const serviceData = serviceServerResponse.data;
                    coverageServiceTittle = serviceData.title;
                    serviceChoices = serviceData.choices;

                    // Fill the postcode in address choice item.
                    if (serviceChoices && serviceChoices.length) {
                        // Insert postcode in address choice item
                        serviceChoices.map((choice) => {
                            if (
                                choice.positions.indexOf(ChoicesPositions.Init) !==
                                -1
                            ) {
                                if (
                                    choice &&
                                    choice.choice_items &&
                                    choice.choice_items.length
                                ) {
                                    choice.choice_items.map((choiceItem) => {
                                        if (choiceItem.type === 'address') {
                                            choiceItem.value = {
                                                postcode,
                                            };
                                        }
                                    });
                                }
                            }
                        });
                    }
                }

                const clearedChoices = simplifyChoices(serviceChoices);
                const sendData: { [key: string]: any } = {
                    service: {
                        id: serviceId,
                        choices: clearedChoices,
                    },
                };

                Object.assign(sendData, additionData);

                const createTransactionResponse = await this.createTransaction(
                    sendData
                );

                this._orderData.bookingType = 'normal';

                const state = this._orderData.getActiveTransactionProgress();
                if (state) {
                    this._orderData.skipStep = true;
                    resolve(state);
                } else {
                    this._orderData.resetActiveBooking();
                    reject({
                        position: ChoicesPositions.Init,
                    });
                }
            } catch (err) {

                this._navigationService.setSkipFlag(true);

                let coverage;
                if (err && err.error) {
                    coverage = this.validateForCoverageError(
                        err.error,
                        coverageServiceTittle,
                        postcode
                    );
                }

                if (coverage) {
                    resolve({
                        position: ChoicesPositions.NoCoverage,
                    });
                } else {
                    reject({
                        position: ChoicesPositions.Init,
                    });
                }
            }
        });
    }
}
