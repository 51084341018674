import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { AuthenticationService } from '@services/authentication.service';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { NGXLogger } from 'ngx-logger';
import { ChoicesPositions } from '../../../../../constants/choice';
import { SpecialData } from '../../../../../providers/special-data.provider';
import { MainDataProvider } from '../../../../../providers/xrm/main-data.provider';
import { OrderData } from '../../../../../providers/xrm/order-data.provider';
import { DataLayerPush } from '../../../../../services/data-layer.service';
import { TrackingInteractionsService } from '../../../../../services/tracking/data-interaction.service';
import { NavigationService } from '../../../../../services/navigation.service';
import { PopupModalsService } from '../../../../../services/popup-modals.service';
import { PostMessageService } from '../../../../../services/post-message.service';
import { BookingTransactionsService } from '../../../../../services/xrm/booking-transactions.service';
import { UserDataService } from '../../../../../services/xrm/user-data.service';
import { QueryParamsService } from './../../../../../services/query-params.service';

declare global {
    interface Window {
        _OBF: any;
    }
}

@Component({
    selector: 'app-exit-popup',
    templateUrl: './exit-popup.component.html',
    styleUrls: ['./exit-popup.component.scss'],
})
export class ExitPopupComponent implements OnInit, OnDestroy {
    public exitOpen: boolean;

    @Input()
    public popupContentDef = {
        title: this._localizationProvider.getLocalizedText('exitPopupTitle'),
        description: this._localizationProvider.getLocalizedText(
            'exitPopupDescription'
        ),
        buttons: {
            stay: this._localizationProvider.getLocalizedText(
                'exitPopupStayButtonLabel'
            ),
            leave: this._localizationProvider.getLocalizedText(
                'exitPopupLeaveButtonLabel'
            ),
        },
    };

    public reasonComment = '';
    public selectedReason = null;

    public leaveReasons = [];

    public submitErr = {
        reason: false,
        comment: false,
    };

    public subscriber;
    public reloadPageBecauseLoginChanges = false;

    constructor(
        private popupModalsService: PopupModalsService,
        private _postMessageService: PostMessageService,
        private _bookingTransactionService: BookingTransactionsService,
        private _navigationService: NavigationService,
        private orderData: OrderData,
        private dataLayerPush: DataLayerPush,
        private _trackingInteractionsService: TrackingInteractionsService,
        private _userDataService: UserDataService,
        private _logger: NGXLogger,
        private _eventTrackingService: EventTrackingService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
        private specialData: SpecialData,
        private _queryParamsService: QueryParamsService,
        private _authenticationService: AuthenticationService
    ) {}

    public ngOnInit() {
        this.initialize();
        // this.exitPopupHandler();
    }

    public ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }

    /**
     * Component initializer
     */
    private initialize(): void {

        this.setLocalizedTexts();

        this.subscriber = this.popupModalsService.exitPopupBSubject.subscribe(
            (data) => {
                const currentStepData =
                    this._navigationService.getCurrentStep();

                if (!currentStepData) {
                    return;
                }

                // * Check if we got change in user status login/anonymous
                this.reloadPageBecauseLoginChanges =
                    (this._userDataService.isUserLogged() &&
                        !this._userDataService.didUserCameLogged()) ||
                    (!this._userDataService.isUserLogged() &&
                        this._userDataService.didUserCameLogged());

                if (
                    currentStepData.position === ChoicesPositions.Init ||
                    currentStepData.position ===
                        ChoicesPositions.Confirmation ||
                    this._bookingTransactionService.getTransactionType() ===
                        'reschedule' ||
                    this._bookingTransactionService.getTransactionType() ===
                        'edit' ||
                    this._bookingTransactionService.getTransactionType() ===
                        'procrossbooking' ||
                    this._bookingTransactionService.getTransactionType() ===
                        'rebook'
                ) {
                    const activeBookingId =
                        this.orderData.activeBooking.getValue()
                            ? this.orderData.activeBooking.getValue().get('id')
                            : null;
                    const userName = this._userDataService.getUserData()
                        ? this._userDataService.getUserData().username
                        : null;

                    if (data.event === 'open') {
                        this.successLeave();
                    }
                    return;
                }

                let leaveModal = {
                    title: {
                        logged: null,
                        anonymous: null,
                        default: this._localizationProvider.getLocalizedText(
                            'exitPopupDefaultTitle'
                        ),
                    },
                    description: { logged: null, anonymous: null, default: '' },
                    leave_reasons: [],
                };

                this._bookingTransactionService
                    .getLeaveReasons(null, true)
                    .then(
                        (leaveReasons) => {
                            leaveModal =
                                leaveReasons.find((leaveReasonModal) => {
                                    return (
                                        currentStepData.position ===
                                        leaveReasonModal.position
                                    );
                                }) || leaveModal;

                            // if (this._userDataService.isUserLogged()) {
                            this.popupContentDef.title =
                                leaveModal.title.logged !== null
                                    ? leaveModal.title.logged
                                    : leaveModal.title.default;
                            this.popupContentDef.description =
                                leaveModal.description.logged !== null
                                    ? leaveModal.description.logged
                                    : leaveModal.description.default;
                            this.leaveReasons = leaveModal.leave_reasons;
                            // } else {
                            //     this.popupContentDef.title = leaveModal.title.anonymous !== null ? leaveModal.title.anonymous : leaveModal.title.default;
                            //     this.popupContentDef.description = leaveModal.description.anonymous !== null ? leaveModal.description.anonymous : leaveModal.description.default;
                            //     this.leaveReasons = [];
                            // }

                            this.exitOpen = data.event === 'open';
                        },
                        () => {
                            // error
                        }
                    );
            }
        );
    }

    public setLocalizedTexts() {

        this.popupContentDef = {
            title: this._localizationProvider.getLocalizedText('exitPopupTitle'),
            description: this._localizationProvider.getLocalizedText(
                'exitPopupDescription'
            ),
            buttons: {
                stay: this._localizationProvider.getLocalizedText(
                    'exitPopupStayButtonLabel'
                ),
                leave: this._localizationProvider.getLocalizedText(
                    'exitPopupLeaveButtonLabel'
                ),
            },
        };
    }

    /**
     * Modal action handler
     * @param action
     */
    public action(action: string): void {
        if (
            this.popupModalsService.exitPopupBSubject.getValue().event ===
            'open'
        ) {
            if (action === 'exit') {
                if (this.validateLeaveReason() && this.leaveReasons.length) {
                    this.submitLeaveReason().then((submit) => {
                        this.successLeave();
                    });
                } else if (!this.leaveReasons.length) {
                    this.successLeave();
                }
            } else {
                this._postMessageService.sendState('exit', {
                    data: false,
                    reload: false,
                });
                this.popupModalsService.exitPopupBSubject.next({
                    event: 'close',
                    data: {},
                });
            }
        } else if (
            this.popupModalsService.exitPopupBSubject.getValue().event ===
            'byclick'
        ) {
            const eventData =
                this.popupModalsService.exitPopupBSubject.getValue().data;
            this.popupModalsService.exitPopupBSubject.next({
                event: 'open',
                data: eventData,
            });
        }
    }

    /**
     * Success obf leave handler
     */
    public successLeave(): void {

        // Reset flag that init step was skipped when you leave OBF
        this._navigationService.setSkipFlag(false);

        this.submitErr = {
            reason: false,
            comment: false,
        };
        this.selectedReason = null;
        this.reasonComment = '';

        const bookingType = this.orderData?.bookingType;
        const exitData =
            this.popupModalsService.exitPopupBSubject.getValue().data;

        exitData.reload = true;

        // check if we have redirect_uri
        if (this._queryParamsService.getQueryParams().return_url) {
            exitData.goTo =
                this._queryParamsService.getQueryParams().return_url;
        }

        this._postMessageService.sendState('exit', { data: true, exitData });

        this.popupModalsService.exitPopupBSubject.next({
            event: 'close',
            data: {},
        });
        this.dataLayerPush.setVisible(false);
        this._trackingInteractionsService.setVisible(false);

        // Set Form state
        this.orderData.setFormState(false);

        if (this.specialData.getDataUserSearch().length) {
            const dataLayerObject = {
                event: 'search-canceled-query',
                cancelType: 'close obf',
                canceledQuery: this.specialData.lastSearch.searchValue,
                canceledQueryLength:
                    this.specialData.lastSearch.searchResultsLength,
                searchedArray: this.specialData.getDataUserSearch(),
            };
            this._eventTrackingService.push(dataLayerObject);
        }
        this._eventTrackingService.push({
            event: 'obf_close',
            version: 'GA4',
            type: 'exit_logo',
            method: bookingType,
        });

        // Get User Data
        const userData = this._userDataService.getUserData();

        // RESET USER DATA IF IS ANONYM
        if (
            userData &&
            userData.type_id === 1 &&
            !this._mainDataProvider.obfEnv.additionalConfig.functionality
                .keepAnonymousSession
        ) {
            this._authenticationService.logout();
        }

        this.orderData.resetActiveBooking();
    }

    /**
     * Method for submitting leave reason
     */
    public submitLeaveReason(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (
                this.orderData.activeBooking.getValue() &&
                this.selectedReason &&
                this.selectedReason.id
            ) {
                const bookingId = this.orderData.activeBooking
                    .getValue()
                    .get('id');
                this._bookingTransactionService.submitLeaveReason(
                    bookingId,
                    {
                        leave_reason_id: this.selectedReason.id,
                        comment: this.reasonComment,
                    },
                    {},
                    (response) => {
                        this._eventTrackingService.push({
                            event: 'leave_reason',
                            reason_value: this.selectedReason.name,
                            reason_comment: this.reasonComment,
                        });
                        resolve(true);
                    }
                );
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Leave reason validation method
     */
    public validateLeaveReason(): boolean {
        const valid = true;
        // * dont delete this is the validation for the leave reason
        // if (this.leaveReasons.length && this.selectedReason == null) {
        //     this.submitErr.reason = true;
        //     valid = false;
        // } else if (this.leaveReasons.length && this.selectedReason != null) {
        //     if (this.selectedReason.requires_comment) {
        //         if (this.reasonComment.replace(/\s/g, '') === '') {
        //             this.submitErr.comment = true;
        //             valid = false;
        //         }
        //     }
        // }

        return valid;
    }

    /**
     * Leave reason selector
     * @param reason
     */
    public setSelectedReason(reason): void {
        this.submitErr = {
            reason: false,
            comment: false,
        };
        this.selectedReason = reason;
    }
}
