<section class="obf-header-section {{ documentDirection }}" *ngIf="!integrate">
    <header class="obf-header-box">
        <div class="container">
            <div class="row justify-content-between">
                <!-- BACK BUTTON + LANGUAGE MOBILE -->
                <div class="d-flex d-lg-none col-3 back-nav-mobile-holder">
                    <div class="obf-back-btn" (click)="handleMobileBack()" *ngIf="showMobileBackFlag">
                        <i class="obf-icon icon-si-arrow-left" aria-hidden="true"
                            data-e2e="previous-position-mobile"></i>
                    </div>
                    <!-- LANGUAGE -->
                    <span *ngIf="hasMultipleLanguages && showLanguageSelectorOnCurrentStep">
                            <div class="obf-fs-input-wrap language-select-holder mobile" [ngClass]="{show: showLanguagesList}" (click)="showLanguagesList = !showLanguagesList">
                                <span class="indicator">
                                    <i *ngIf="getCurrentLanguageFlag()" [style.background-image]="'url(' + getCurrentLanguageFlag() + ')'"></i>
                                    <span *ngIf="!getCurrentLanguageFlag()">{{ currentLanguage }}</span>
                                    <span class="iconarrow"></span>
                                </span>
                                <div class="holder-overlay">
                                    <div class="selector">
                                        <button class="obf-btn-close icon-si-close-cancel"></button>
                                        <!-- <span class="language-list-title">{{ selectLanguageTitle }}</span> -->
                                        <ul>
                                            <li *ngFor="let item of availableLanguages" (click)="onLanguageSelect(item)">
                                                <i *ngIf="item.icon_url" [style.background-image]="'url(' + item.icon_url + ')'"></i>
                                                {{ item.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </span>
                </div>

                <!-- LOGO -->
                <div class="col-xl-2 col-lg-3 col-6 obf-logo-wrap">
                    <img *ngIf="isLocalhost; else otherLogoLogic" [src]="logoUrl" alt="{{brandName}}" data-e2e="header-brand-logo" (click)="backToSite()" />
                    
                    <ng-template #otherLogoLogic>
                        <img class="vector-logo" *ngIf="isLogoInSvgFormat" [src]="logoUrl" height="45" alt="{{brandName}}" data-e2e="header-brand-logo" (click)="backToSite()"/>
                        <img class="image-logo" *ngIf="!isLogoFromFileServer && !isLogoInSvgFormat" [src]="logoUrl" alt="{{brandName}}" data-e2e="header-brand-logo" (click)="backToSite()"/>
                        <img class="server-logo" *ngIf="isLogoFromFileServer && !isLogoInSvgFormat" [src]="logoUrl + '/135'" [srcset]="srcSetValue" alt="{{brandName}}" data-e2e="header-brand-logo" (click)="backToSite()" />
                    </ng-template>
                </div>

                <!-- PROGRESS BAR -->
                <div class="col-xl-6 d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-flex progress-steps-holder" *ngIf="showProgressBar && !isInitStep">
                    <div class="progress-steps" >
                        <ul>
                            <li *ngFor="let step of stepsProgress; let i = index" [attr.data-e2e]="step.dateE2e"
                                class="{{ step.status }}">
                                <span class="step-holder" [ngClass]="{first: i === 0 || isReschedule && i === 1}"
                                    *ngIf="((noPrice && step.title.noPriceTitle) || (!noPrice && step.title.priceTitle)) && !(step.step === 1 && isReschedule)">
                                    <span class="number">
                                        <span>{{ isReschedule ? step.step - 1 : step.step  }}</span>
                                        <span class="obf-icon icon-si-check"></span>
                                    </span>
                                    <span class="obf-title">
                                        {{ noPrice ? step.title.noPriceTitle : step.title.priceTitle }}
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- LANGUAGE DESKTOP + CONSIERGE + PHONE + MENU -->
                <div class="col-xl-4 col-lg-4 col-3 obf-user-wrap">

                    <!-- LANGUAGE -->
                    <span class="d-none d-lg-inline-block" *ngIf="hasMultipleLanguages && showLanguageSelectorOnCurrentStep">
                        <div class="obf-fs-input-wrap language-select-holder">
                            <span class="indicator">
                                <i *ngIf="getCurrentLanguageFlag()" [style.background-image]="'url(' + getCurrentLanguageFlag() + ')'"></i>
                                <span *ngIf="!getCurrentLanguageFlag()">{{ currentLanguage }}</span>
                                <span class="iconarrow"></span>
                            </span>
                            <div class="selector">
                                <!-- <span class="language-list-title">{{ selectLanguageTitle }}</span> -->
                                <ul>
                                    <li *ngFor="let item of availableLanguages" (click)="onLanguageSelect(item)">
                                        <i *ngIf="item.icon_url" [style.background-image]="'url(' + item.icon_url + ')'"></i>
                                        {{ item.title }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </span>

                    <!-- CONSIERGE -->
                    <div class="concierge-menu-wrapper" *ngIf="consiergeMenu; else sitePhone">
                        <div class="concierge-menu-desktop">
                            <div class="concierge-wrapper">
                                <span class="head-holder">
                                    <i class="obf-icon icon-si-consierge-full"></i>
                                    <span class="concierge-title">Concierge</span>
                                </span>

                                <div class="menu-options">
                                    <ul class="menu-options-list">
                                        <li>
                                            <a href="mailto:{{ consiergeMenu?.email }}" target="_parent">
                                                <i class="obf-icon icon-si-mail"></i>
                                                <span class="menu-option-text">{{ consiergeMenu?.email }}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:{{ consiergeMenu?.phoneNumber }}" target="{{ attrTarget }}">
                                                <i class="obf-icon icon-si-call"></i>
                                                <span
                                                    class="menu-option-text">{{ consiergeMenu?.formattedPhoneNumber }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="concierge-menu-mobile">
                            <nav class="menu">
                                <input type="checkbox" href="#" class="menu-open" name="menu-open" id="menu-open" />
                                <label class="menu-open-button" for="menu-open">
                                    <span class="menu-head">
                                        <i class="obf-icon icon-si-consierge-full"></i>
                                        <i class="obf-icon icon-si-consierge-empty"></i>
                                    </span>
                                </label>

                                <a href="mailto:{{ consiergeMenu?.email }}" target="{{ attrTarget }}"
                                    class="menu-item menu-mail-link"> <i class="obf-icon icon-si-mail"></i> </a>
                                <a href="tel:{{ consiergeMenu?.phoneNumber }}" target="{{ attrTarget }}"
                                    class="menu-item menu-phone-link"> <i class="obf-icon icon-si-call"></i> </a>

                            </nav>
                        </div>
                    </div>
                    
                    <!-- PHONE -->
                    <ng-template #sitePhone>
                        <div class="obf-phone-container cursor-pointer" *ngIf="!hidePhone && formatted_phone">
                            <a class="obf-phone" (click)="clickToCall(formatted_phone)" href="tel:{{ formatted_phone }}">
                                <span appDynamicColorChanger class="obf-icon icon-si-call"></span>
                                <span class="numbers">{{ phone }}</span>
                            </a>
                        </div>
                    </ng-template>

                    <!-- MENU -->
                    <div class="web-account" data-wa-login="desktop" [attr.data-wa-client-name]="waUserMenuConfig.clientName"
                    [attr.data-wa-user-member]="waUserMenuConfig.member" [attr.data-wa-user-id]="waUserMenuConfig.userId"
                        *ngIf="!hideAccountsMenu && isUserLogged && waUserMenuConfig && bookingType !== 'procrossbooking' && !disableAccounts"
                        [ngClass]="{ 'obf-no-separator': hidePhone }">
                        <div class="logged" (click)='toggleLoginMenu = !toggleLoginMenu' [ngClass]="{ 'show-it': toggleLoginMenu }" >
                            <!-- <div id="desktop-menu" data-wa-user-id="{{waUserMenuConfig.userId}}"></div> -->
                            <div class="user-img"
                                *ngIf="avatarImgUrl.indexOf('basic') === -1"
                                [ngStyle]="{ background: ' url(' + avatarImgUrl + ') center / cover no-repeat ' }">
                            </div>
                            <div class="user-initials" *ngIf="avatarImgUrl.indexOf('basic') !== -1">
                                <span>
                                    {{ getNameInitials(waUserMenuConfig.clientName) }}
                                </span>
                            </div>
                            <span class="iconarrow"></span>
                            <ul class="wa-links">
                                <ng-container *ngFor="let link of waUserMenuConfig.waLinks">
                                    <li class="menu-item" *ngIf="link.menuName !== 'logout'">
                                        <a target="_blank"
                                            (click)="$event.stopPropagation()"
                                            href="{{ link.pageUrl }}?redirect_uri={{ origin }}&int_src={{ link.pageName }}{{ gclid ? '&gclid=' + gclid : '' }}"
                                            rel="noopener noreferrer">
                                            <span *ngIf="link.iconUrl" class="icon-image-holder" [ngStyle]="{'background': 'url(' + link.iconUrl + ') 0 50% no-repeat' }"></span>
                                            <span *ngIf="link.iconUrl === ''" class="menu-item-icon" 
                                                [ngClass]="{
                                                    'wa-icon-home': link.iconClass === 'page_icon_dashboard',
                                                    'wa-icon-calendar': link.iconClass === 'page_icon_bookings',
                                                    'wa-icon-wallet': link.iconClass === 'page_icon_credits',
                                                    'wa-icon-diamond': link.iconClass === 'page_icon_club',
                                                    'wa-icon-referral': link.iconClass === 'page_icon_invite_and_earn',
                                                    'wa-icon-settings': link.iconClass === 'page_icon_settings',
                                                    'wa-icon-treats': link.iconClass === 'page_icon_treats',
                                                    'wa-icon-notifications': link.iconClass === 'page_icon_notifications'
                                                }">
                                            </span>
                                            <span *ngIf="link.iconUrl !== '' && !link.iconUrl" class="menu-item-icon {{link.iconClass}}">
                                            </span>
                                            <span style="margin-right: 3px" class="menu-item-title"
                                                [innerHTML]="link.pageName"></span>
                                            <span class="additional-menu-icon icon-notification-counter visible"
                                                *ngIf="link.itemCount && link.itemCount !== 0">
                                                {{ link.itemCount }}
                                            </span>
                                            <span class="additional-menu-icon " [ngClass]="['menu-item-icon', link.trailingIconClass]"
                                                *ngIf="link.trailingIconClass">
                                            </span>
                                        </a>
                                    </li>
                                    <li class="menu-item" *ngIf="link.menuName === 'logout'" (click)="userLogout()">
                                        <div class="last-list-item">
                                            <span *ngIf="link.iconUrl" class="icon-image-holder" [ngStyle]="{'background': 'url(' + link.iconUrl + ') 0 50% no-repeat' }"></span>
                                            <span *ngIf="!link.iconUrl" [ngClass]="['menu-item-icon', 'wa-icon-exit']"></span>
                                            <span style="margin-right: 3px" class="menu-item-title"
                                            [innerHTML]="link.pageName"></span>
                                            <span class="additional-menu-icon icon-notification-counter visible"
                                                *ngIf="link.itemCount && link.itemCount !== 0">
                                                {{ link.itemCount }}
                                            </span>
                                            <span class="additional-menu-icon " [ngClass]="['menu-item-icon', link.trailingIconClass]"
                                            *ngIf="link.trailingIconClass">
                                            </span>
                                        </div>
                                    </li>
                                </ng-container>
                                <li class="menu-item" *ngIf="!isMenuContainLogout(waUserMenuConfig.waLinks)" (click)="userLogout()">
                                    <span class="last-list-item">
                                        <span class="menu-item-icon wa-icon-exit"></span>
                                        <span class="menu-item-title">{{ logoutButtonLabelText }}</span>

                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="!hideAccountsMenu && !isUserLogged && bookingType !== 'procrossbooking' && !disableAccounts && !hideLoginButton"
                        class="obf-login-button-container" [ngClass]="{ 'obf-no-separator': hidePhone }">
                        <span class="obf-login">
                            <div class="user-img cursor-pointer"
                                (click)="openLoginRegisterModal($event)"
                                [ngStyle]="{ background: ' url(' + avatarImgUrl + ') center / cover no-repeat ' }">
                            </div>
                            <span class="iconarrow"></span>

                            <div class="accounts-menu-list-wrapper">

                                <div class="accounts-menu-list not-logged-content">
                                    <div class="has-account-section">
                                        <span class="title">{{ openLoginFormLabelText }}</span>
                                        <app-button
                                            (click)="openLoginRegisterModal($event, 'login')"
                                            [label]="loginButtonLabelText"
                                            [type]="'primary'"
                                            [state]="'default'"
                                        >
                                        </app-button>
                                    </div>
                                    <a class="no-account-section cursor-pointer" (click)="openLoginRegisterModal($event, 'register')">
                                        <span class="click-to-register">{{ noAccountLabel1Text }}</span>
                                        <span localizedText>{{ noAccountLabel2Text }}</span>
                                    </a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <app-exit-popup></app-exit-popup>
        </div>
    </header>
    <div class="obf-progress-bar-parent">
        <div class="obf-progress-bar-child" [style.width.%]="bookingProgress"></div>
    </div>
</section>
